<template>
  <v-card
    outlined
    class="pa-3"
  >
    <v-hover #default="{ hover: isHovered }">
      <div
        class="d-flex justify-space-between"
      >
        <div>
          <router-link
            class="text-caption primary--text"
            :to="{ name: 'workflow-detail', params: { id: data.id } }"
          >
            <h3 class="mb-2">
              {{ data.name }}
            </h3>
          </router-link>
          <div class="d-flex align-center">
            <v-chip
              small
              class="mt-1"
              :color="status ? 'primary' : 'secondary'"
            >
              {{ status ? 'Active': 'Inactive' }}
            </v-chip>
            <v-switch
              :value="status"
              inset
              flat
              hide-details
              class="ml-5 mt-0"
              :loading="loadingStatusWorkflow"
              :disabled="loadingStatusWorkflow"
              readonly
              @click="onChange(data.id)"
            />
          </div>
        </div>
        <div class="d-flex align-center">
          <v-menu
            offset-y
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-slide-x-reverse-transition mode="out-in">
                <v-icon
                  v-show="isHovered"
                  :key="1"
                  size="24px"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-slide-x-reverse-transition>
            </template>
            <v-list>
              <v-list-item
                @click="$router.push({ name: 'workflow-detail', params: { id: data.id } })"
              >
                <v-list-item-title>
                  Lihat Detail
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$emit('onOpen', data.id)"
              >
                <v-list-item-title>
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-divider
                class="my-2"
              />
              <v-list-item
                @click="onDelete(data.id)"
              >
                <v-list-item-title>
                  Hapus
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-hover>
  </v-card>
</template>

<script>
import Vue from 'vue'
import useAutomationV2 from '@/composables/useAutomation'
import { onMounted, ref } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const status = ref(null)

    onMounted(() => {
      status.value = !props.data.is_disabled
    })

    const {
      changeStatusWorkflow, loadingStatusWorkflow,
      deleteWorkflow,
    } = useAutomationV2({})

    const onChange = id => {
      changeStatusWorkflow(id, !status.value).then(() => {
        status.value = !status.value
        emit('change')
      })
    }

    const onDelete = id => {
      Vue.$dialog({
        title: 'Hapus Workflow',
        text: 'Apakah anda yakin ingin menghapus workflow ini?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result) {
          deleteWorkflow(id)
            .then(() => {
              emit('change')
            })
        }
      })
    }

    return {
      status,
      changeStatusWorkflow,
      onChange,
      onDelete,
      icons: {
        mdiDotsVertical,
      },
      loadingStatusWorkflow,
      deleteWorkflow,
    }
  },
}
</script>

<style>

</style>
