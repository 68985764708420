import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-3",attrs:{"outlined":""}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isHovered = ref.hover;
return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('router-link',{staticClass:"text-caption primary--text",attrs:{"to":{ name: 'workflow-detail', params: { id: _vm.data.id } }}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")])]),_c('div',{staticClass:"d-flex align-center"},[_c(VChip,{staticClass:"mt-1",attrs:{"small":"","color":_vm.status ? 'primary' : 'secondary'}},[_vm._v(" "+_vm._s(_vm.status ? 'Active': 'Inactive')+" ")]),_c(VSwitch,{staticClass:"ml-5 mt-0",attrs:{"value":_vm.status,"inset":"","flat":"","hide-details":"","loading":_vm.loadingStatusWorkflow,"disabled":_vm.loadingStatusWorkflow,"readonly":""},on:{"click":function($event){return _vm.onChange(_vm.data.id)}}})],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c(VMenu,{attrs:{"offset-y":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(isHovered),expression:"isHovered"}],key:1,attrs:{"size":"24px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'workflow-detail', params: { id: _vm.data.id } })}}},[_c(VListItemTitle,[_vm._v(" Lihat Detail ")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$emit('onOpen', _vm.data.id)}}},[_c(VListItemTitle,[_vm._v(" Edit ")])],1),_c(VDivider,{staticClass:"my-2"}),_c(VListItem,{on:{"click":function($event){return _vm.onDelete(_vm.data.id)}}},[_c(VListItemTitle,[_vm._v(" Hapus ")])],1)],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }