import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c(VForm,{ref:"telegramForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('telegram-bot-select',{ref:"telegramUserSelect",on:{"input":_vm.changeToken},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Akun Bot Telegram","details":[
                'Akun Bot Telegram yang akan digunakan untuk mengirim pesan' ]}})]},proxy:true}]),model:{value:(_vm.selectedToken),callback:function ($$v) {_vm.selectedToken=$$v},expression:"selectedToken"}}),_c('XyzTransitionGroup',{attrs:{"appear":"","xyz":"fade down-4 out-delay-0 stagger-1 out-stagger-1"}},[(_vm.selectedToken)?[_c('div',{key:1,staticClass:"d-flex align-center"},[_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.telegramChatList,"item-value":"chat_room_id","item-text":"group_name","flat":"","outlined":"","attach":"","dense":"","property-id":1601,"disabled":!_vm.selectedToken,"loading":_vm.loadingTelegramChatList,"label":"Chat","hide-details":"auto","placeholder":"Pilih Chat","rules":[_vm.required]},on:{"change":_vm.changeChat},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.group_name ? item.group_name : _vm.resolveChatName(item))+" ")]),_c(VListItemSubtitle,{staticClass:"caption font-weight-semibold"},[_vm._v(" "+_vm._s(item.group_name ? 'Group Chat' : 'Private Chat')+" ")])],1)]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.group_name ? item.group_name : _vm.resolveChatName(item))+" ")])]}}],null,false,3577862582),model:{value:(_vm.selectedChat),callback:function ($$v) {_vm.selectedChat=$$v},expression:"selectedChat"}}),_c('workflow-field-info',{attrs:{"margin-top":'mt-3',"title":"Chat","details":[
                  'Chat yang akan dikirimi pesan',
                  'Bot hanya bisa mengirim pesan ke orang yang pernah mengirim pesan ke bot ini'
                ]}})],1),_c('workflow-input',{key:2,ref:"telegramInput",staticClass:"my-4",attrs:{"property-id":1602,"placeholder":"Text","variables":_vm.options,"required":"","long":""},on:{"input":function($event){return _vm.onInput(_vm.formData)}},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('workflow-field-info',{attrs:{"margin-top":'mt-1',"title":"Text","details":[
                    'Isi dari pesan yang dikirim' ]}})]},proxy:true}],null,false,2183518769),model:{value:(_vm.formData['1602']),callback:function ($$v) {_vm.$set(_vm.formData, '1602', $$v)},expression:"formData['1602']"}})]:_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }