<template>
  <div
    class="d-flex"
  >
    <template
      v-for="(el, index) in elements.slice(0, limit)"
    >
      <slot
        name="default"
        :data="{ ...el, index }"
      >
        <span :key="index">{{ el }}</span>
      </slot>
    </template>
    <v-menu
      v-if="elements.length > limit"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <slot
            name="others-activator"
            :data="{ limit: elements.length - limit }"
          >
            {{ elements.length - limit }} lainnya
          </slot>
        </div>
      </template>
      <v-card class="pa-2">
        <div
          v-for="(el, index) in elements.slice(limit, elements.length)"
          :key="index"
        >
          <slot
            :name="hasSlot('others') ? 'others' : 'default'"
            :data="{ ...el, index: index + limit }"
          >
            {{ el }}
          </slot>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    elements: {
      type: Array,
      default: () => [],
      required: true,
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  setup(_, { slots }) {
    const hasSlot = name => !!slots[name]

    return {
      hasSlot,
    }
  },
}
</script>

<style>

</style>
