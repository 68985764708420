<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Workflow</h3>
          <span class="d-block text-subtitle-2 mt-1">Buat aksi otomatis dengan Oriens CRM Workflow!</span>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="ms-auto d-flex"
        >
          <v-btn
            color="primary"
            block
            @click="$refs.workflowForm.show()"
          >
            Buat Workflow Baru
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <div class="d-flex justify-space-between mb-4">
          <h4 class="font-medium">
            Daftar Workflow
          </h4>
        </div>
        <v-row
          dense
        >
          <v-col
            v-for="workflow in workflowList"
            :key="workflow.id"
            cols="12"
            md="6"
          >
            <workflow-card
              :data="workflow"
              @change="refetchWorkflow"
              @onOpen="editWorkflow"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>

    <WorkflowForm
      ref="workflowForm"
      @onSubmitWorkflow="refetchWorkflow"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import useAutomationV2 from '@/composables/useAutomation'
import WorkflowCard from './components/WorkflowCard.vue'
import WorkflowForm from './WorkflowForm.vue'

export default {
  components: {
    WorkflowCard,
    WorkflowForm,
  },
  setup() {
    const { workflowList, fetchWorkflowListV2 } = useAutomationV2({ pagination: { limit: 20, offset: 0 } })
    const workflowForm = ref(null)
    onMounted(() => {
      fetchWorkflowListV2()
    })

    const editWorkflow = id => {
      workflowForm.value.showEdit(id)
    }

    const refetchWorkflow = () => {
      fetchWorkflowListV2()
    }

    return {
      workflowList,
      refetchWorkflow,
      editWorkflow,
      workflowForm,
    }
  },
}
</script>

<style>

</style>
