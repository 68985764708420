<template>
  <v-autocomplete
    ref="jobAutoSuggest"
    v-model="inputData"
    placeholder="Cari job..."
    :label="label"
    outlined
    dense
    :multiple="multiple"
    :loading="loadingJob"
    :items="options"
    item-value="id"
    item-text="name"
    hide-details="auto"
    filled
    no-filter
    solo
    flat
    clearable
    chips
    :rules="rules"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
    @click:clear="onClear"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Job tidak ditemukan
        </p>
      </div>
    </template>
    <template
      #item="{ item }"
    >
      <v-card
        outlined
        class="pa-3 w-full rounded my-1"
      >
        <div class="d-flex justify-space-between w-full">
          <div class="d-flex flex-column">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="text-subtitle-2 mb-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.name }}
                </span>
              </template>
              <span>{{ item.description ? `Deskripsi : ${item.description}` : 'Tidak ada deskripsi' }}</span>
            </v-tooltip>
            <div class="d-flex">
              <v-chip
                class="mr-1"
                x-small
                color="primary"
              >
                {{ item.job_type.name }}
              </v-chip>
              <v-chip
                class="mr-1"
                x-small
                :color="item.status.color"
              >
                {{ item.status.name }}
              </v-chip>
            </div>
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                x-small
                :color="priorityColor(item.priority.id)"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.priority.name }}
              </v-chip>
            </template>
            <span>Prioritas Job</span>
          </v-tooltip>
        </div>
      </v-card>
    </template>
    <template #append-item>
      <div
        v-intersect="onIntersect"
        class="text-center"
      >
        <p v-if="loadingJob">
          Memuat data...
        </p>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import useJob from '@/composables/useJob'

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    propertyId: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Job',
    },
    filterCustomer: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const jobAutoSuggest = ref()
    const isEmpty = ref(false)
    const searchQuery = ref('')

    const filterJobs = computed(() => ({
      customer: props.filterCustomer ? [props.filterCustomer.id] : null,
      search: searchQuery.value ? searchQuery.value : null,
    }))

    const {
      loadingJob, fetchJobsAutoSuggest, jobList,
      debouncedFetchJobs, priorityColor,
      listenScrollAutoSuggestFetchMore,

    } = useJob({
      filter: filterJobs,
    })

    const onIntersect = entry => {
      if (entry[0].isIntersecting) {
        listenScrollAutoSuggestFetchMore()
      }
    }

    const encoded = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const inputData = ref(null)
    watch(inputData, value => {
      encoded.value = value
    })

    const filterCustomerProps = computed(() => props.filterCustomer)

    watch(filterCustomerProps, () => {
      emit('input', null)

      fetchJobsAutoSuggest()
    })

    const options = computed(() => {
      if (inputData.value) {
        return Array.isArray(inputData.value) ? [
          ...jobList.value,
          ...inputData.value,
        ] : [
          ...jobList.value,
          inputData.value,
        ]
      }

      return jobList.value
    })

    const onSearch = data => {
      searchQuery.value = data
      debouncedFetchJobs()
    }

    const onIndex = () => {
      isEmpty.value = jobAutoSuggest.value.filteredItems.length === 0
    }

    const onClear = () => {
      searchQuery.value = ''
      fetchJobsAutoSuggest()
    }

    onMounted(() => {
      fetchJobsAutoSuggest().then(() => {
        if (props.value) {
          inputData.value = props.value
        }
      })
    })

    return {
      jobAutoSuggest,
      isEmpty,

      loadingJob,
      jobList,
      fetchJobsAutoSuggest,
      priorityColor,

      inputData,
      options,

      onIndex,
      onSearch,
      onIntersect,
      onClear,
    }
  },
}
</script>

<style>

</style>
